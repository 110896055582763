import './AboutMeList.css';
import AboutMeElement from './AboutMeElement';

function AboutMeList({ icon, text }) {
    const textGamepad = "I’ve been a gamer my whole life—PlayStation, PC, Nintendo, even board games. It’s always been something I’ve loved, and that’s what inspired me to get into game development for my bachelor’s degree, along with my passion for programming. A lot of people told me it wouldn’t be as easy as it sounds, that just because I have two passions doesn’t mean it’s the right path for me. But they were wrong, and I’m really glad I followed what I love.";
    const textSchool = "In my Bachelor of Digital Art & Entertainment (DAE), I learned a lot about 3D modeling, animation, and game development, but the programming side was a big focus too. I got really skilled at coding for game engines, creating interactive environments, and using scripting to optimize game mechanics. With this, I can work as a game developer, but also in more technical roles like software development or creating tools for artists. It really gives me flexibility between creative and technical paths in the entertainment and tech industries.";
    const textGlobe = "I did my Bachelor's in Digital Art & Entertainment in Belgium, so I lived there for most of my studies. But for the last semester, I did my internship at Bolverk Games in Copenhagen, Denmark. After finishing my internship, I graduated and decided to stay in Denmark.";

  return (
        <div className='aboutme-list'>
            <AboutMeElement icon="fa-solid fa-gamepad" text={textGamepad}/>
            <AboutMeElement icon="fa-solid fa-school" text={textSchool}/>
            <AboutMeElement icon="fa-solid fa-globe" text={textGlobe}/>
        </div>
  );
}

export default AboutMeList;