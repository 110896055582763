import React, { createContext, useState } from 'react';
import utils from '../Utils/Utils.js';

export const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [highlightedProject, setHighlightedProject] = useState(utils.GetProjectInfo(0));

  return (
    <StateContext.Provider value={{ highlightedProject, setHighlightedProject }}>
      {children}
    </StateContext.Provider>
  );
};