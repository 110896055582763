import './Table.css';

function Table( {data} ) {
    return (
        <>
            <h1>{data?.Title}</h1>        
            <div className="vertical-list-element">
                <table>
                    <thead>
                        <tr>
                            {data?.titles?.map((title, index) => (
                                <th key={index}>{title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.rows?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row?.columns?.map((column, colIndex) => (
                                    <td key={colIndex}>{column}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

  
 export default Table;