/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { forwardRef } from 'react'
import { useGLTF } from '@react-three/drei'

const TV = forwardRef((props, ref) => {
  const { nodes, materials } = useGLTF('/TV.glb')
  return (
    <group ref={ref} {...props} dispose={null} scale={[0.05, 0.05, 0.05]}>
      <group position={[0, -14.132, 0]} rotation={[Math.PI / 2, 0, 0]} scale={0.164}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.heads_first_1.geometry}
          material={materials.Screen}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.heads_first_2.geometry}
          material={materials.Chassis}
        />
      </group>
    </group>
  )
});

export default TV;

useGLTF.preload('/TV.glb')
