import './HighlightedProjectMain.css';
import HighlightedProjectTitleElement from './HighlightedProjectTitleElement';
import ProjectScrollList from './ProjectScrollList';
import HighlightedProjectInfoElement from './HighlightedProjectInfoElement';
import ScrollToHash from './ScrollToHash';

import React, { useContext } from 'react';
import { StateContext } from './ContextWrapper';

function HighlightedProjectMain() {
    const { highlightedProject } = useContext(StateContext);

    return (
        <>
            <ScrollToHash />
            <div className='main-content' id='project-top'>
                <HighlightedProjectTitleElement title={highlightedProject?.info?.title.text} subtitle={highlightedProject?.info?.description}
                hyperLink={highlightedProject?.info?.title.link} icon='fa-brands fa-github' contributors={highlightedProject?.info?.contributors} />
                <ProjectScrollList/>
            </div>
            
            <div className='additional-content'>

                {highlightedProject?.content?.map((element, index) => (
                    <HighlightedProjectInfoElement Source={element.src} title={element.section} subtitle={element.description} type={element.type} reverse={index % 2} />)                
                )}
            </div>
        </>
    );
  }
  
 export default HighlightedProjectMain;
  