import PageTitle from '../Components/PageTitle';
import './AboutMeApp.css';

import Table from '../Components/Table';
import BulletPointTable from '../Components/BulletPointTable';
import utils from '../Utils/Utils';

const AboutMeTitle = "About Me";
const AboutMeSubtitle = ["Hello! I'm Gauthier Gesquiere, a passionate AI Programmer with a love for creating immersive virtual experiences. I worked at Bolverk Games where I found my passion for Copenhagen.",
                          "My journey in the world of programming began with a fascination for AI and game development. Over the years, I've honed my skills in rendering techniques, programming, and optimizing game performance. When I'm not coding, you can find me exploring new technologies, hiking, or enjoying a good book."
                          ];
  
const ContactMeTitle = "Contact Me";
const ContactMeSubtitle = ["If you have any questions, suggestions, or just want to say hello, feel free to reach out. You can use e-mail below or contact me via LinkedIn."]

function AboutMeApp() {
  document.body.style.overflowY = "visible";

  return (
    <>      
        <PageTitle title={AboutMeTitle} subtitle={AboutMeSubtitle} isBoldDescription={false} />

        <div className='container'>
          <div className='left-column'>
            <div className='vertical-list'>
              <Table data={utils.GetEducationData()}/>
              <Table data={utils.GetSkillsData()}/>
            </div>
          </div>
          <div className='right-column'>
            <BulletPointTable data={utils.GetExperienceData()}/>

            <div class="download-resume">
                <a href="/images/ResumeGauthierGesquiere.pdf" download class="download-button">
                    Download My Resume
                </a>
            </div>
          </div>
        </div>

        <PageTitle title={ContactMeTitle} subtitle={ContactMeSubtitle} mail={"gesquiere.gauthier@gmail.com"} />
    </>
  );
}

export default AboutMeApp;
