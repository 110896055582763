import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './Components/ContextWrapper';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GameApp from './Apps/GameApp';
import MainApp from './Apps/MainApp';
import GalleryApp from './Apps/GalleryApp';
import AboutMeApp from './Apps/AboutMeApp';
import Header from './Components/Header';
import Footer from './Components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode> 
    <Router>
      <StateProvider>
        <div>
          <Header/>

          <Routes>
            {/* <Route path="/game" element={<GameApp />} /> */}
            <Route path="/" element={<MainApp />} />
            <Route path="/gallery" element={<GalleryApp />} />
            <Route path="/about" element={<AboutMeApp />} />
          </Routes>

          <Footer/>
        </div>
      </StateProvider>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
