/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: Omer Bhatti (https://sketchfab.com/OmerBhatti)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/synthwave-80s-retro-style-foreground-5184125634af45a4b1a657b7d51c3d6e
Title: Synthwave | 80s retro style foreground
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Synthwave(props) {
  const { nodes, materials } = useGLTF('/synthwave__80s_retro_style_foreground.glb')
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Floor_Material001_0.geometry}
        material={materials['Material.001']}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Wireframe_Material_0.geometry}
        material={materials.Material}
        rotation={[-Math.PI / 2, 0, 0]}
        scale={100}
      />
    </group>
  )
}

useGLTF.preload('/synthwave__80s_retro_style_foreground.glb')