import './PopularProjectList.css';
import PopularProjectElement from './PopularProjectElement';


function PopularProjectList() {
    return (
        <div className='popular-projects'>
            <div class="popular-projects-titel">
                <h1>POPULAR PROJECTS</h1>
            </div>

            <div className='popular-projects-list'>
                <ul>
                    <PopularProjectElement imgSource="/images/TheFloorIsHackedTemplate.jpg" title={"The Floor Is Hacked"} subtitle={"Group-Project"} projectIndex={0} />
                    <PopularProjectElement imgSource="/images/Connect4Template.jpg" title={"Connect 4"} subtitle={"AI-Project"} projectIndex={1} />
                    <PopularProjectElement imgSource="/images/SenseiVRTemplate.jpg" title={"Sensei VR"} subtitle={"Group-Project"} projectIndex={3} />
                    <PopularProjectElement imgSource="/images/EngineTemplate.jpg" title={"C++ Engine"} subtitle={"Custom Engine"} projectIndex={2} />
                </ul>
            </div>
        </div>
    );
  }
  
  export default PopularProjectList;
  