import './PageTitle.css';

function PageTitle({ title, subtitle, isBoldDescription, mail = null }) {

    if (isBoldDescription)
    {
        var descriptionStyle = {    //Cant be let because it goes out of scope
            fontSize: '1.5em',
            fontWeight: 'bold'          
          }        
    }

    return (
        <div className='titel-about-me'>
            <h1>{title}</h1>

            {subtitle?.map((text) => (
                <p style={descriptionStyle}>{text}</p>
            ))}            
            <i>{mail}</i>
        </div>
    );
  }
  
  export default PageTitle;
  