import './HighlightedProjectInfoElement.css';

function HighlightedProjectInfoElement({ Source, title, subtitle, type, reverse }) {
    let className = 'content-block' + (reverse ? ' reverse' : '')

    return (
      <div className={className}>
            {reverse ? (null) : (type === 'image' ? (<img className='content-img' img src={Source} alt='' />) : (<video className='content-img' img src={Source} type='video/mp4' autoplay loop muted controls source />))}            
            <div className='content-text'>
                <h2>{title}</h2>
                <p>{subtitle}</p>
            </div>
            {reverse ? (type === 'image' ? (<img className='content-img' img src={Source} alt='' />) : (<video className='content-img' img src={Source} type='video/mp4' autoplay loop muted controls source />)) : (null)}            
      </div>
    );
  }
  
  export default HighlightedProjectInfoElement;
  