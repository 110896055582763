import './AboutMeElement.css';

function AboutMeElement({ icon, text }) {
  return (
        <div className='list-element'>
            <i className={icon}></i>
            <p>{text}</p>
        </div>
  );
}

 export default AboutMeElement;