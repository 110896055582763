import { useContext } from 'react';
import utils from '../Utils/Utils';
import './GalleryItem.css';
import { StateContext } from './ContextWrapper.js';
import { Link } from 'react-router-dom';

function GalleryItem( {title, source, projectIndex} ) {
    const { setHighlightedProject } = useContext(StateContext);

    const handleClick = () => {
        const projectInfo = utils.GetProjectInfo(projectIndex);
        setHighlightedProject(projectInfo);
      };

    return (
        <div class="gallery-item" onClick={handleClick}>
            <Link to="/#project-top">
                {source && source.includes('.mp4') ? (<video src={source} alt="Project 2" class="gallery-img" autoplay loop muted controls source />) : (<img src={source} alt="Project 2" class="gallery-img" />)}                    
                <div class="gallery-info">
                    <h3>{title}</h3>
                <p></p>
                </div>
            </Link>
        </div>
    );
  }
  
 export default GalleryItem;