import './BulletPointTable.css';

function BulletPointTable( {data} ) {
  return (
        <div>
            <h1>{ data?.Title }</h1>
            <div class="job-container">
                {data?.elements?.map((element) => (
                    <>
                        <h1>{element?.title}</h1>
                        <p class="company">{element?.company}</p>

                        <div class="content">
                            {element?.details?.map((content) => (
                                <div class="details">
                                    <h3>{content?.title}</h3>
                                    <ul>
                                        {content?.list?.map((text) => (
                                            <li>{text}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </>
                ))}
            </div>
        </div>
  );
}

 export default BulletPointTable;