import './ProjectScrollList.css';
import ProjectScrollElement from './ProjectScrollElement';

import utils from '../Utils/Utils.js'

function ProjectScrollList() {
    const ProjectListTitles = utils.GetProjectList();

    return (
        <div className='project-scroll-list'>
            {ProjectListTitles.map((title, index) => (
                <ProjectScrollElement title={title} projectIndex={index} key={index} />
            ))}

        </div>
    );
  }
  
 export default ProjectScrollList;