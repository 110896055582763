import './Header.css';
import { Link } from 'react-router-dom';

function Header() {
  return (
        <div>
            <div class="backgroundimage">
                <img src="/images/TheFloorIsHackedBackground.png" alt=""/>
            </div>

            <header>
                <div>
                    {/* Currently not finished */}
                    <Link to="/"><i class="fa-solid fa-chess-queen"></i></Link>
                    <nav>
                        <li><Link to="/">HOME</Link></li>
                        <li><Link to="/gallery">GALLERY</Link></li>
                        <li><Link to="/about">ABOUT ME</Link></li>
                    </nav>
                    <span></span>
                </div>
            </header>
        </div>
  );
}

 export default Header;