/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function Environment(props) {
  const { nodes, materials } = useGLTF('/Environment.glb')
  return (
    <group {...props} dispose={null} scale={3} position={[-10,-20, 100]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_27.geometry}
        material={materials.sphere}
        position={[3.78, -0.747, -8.385]}
        rotation={[0, 1.228, 0]}
        scale={52.195}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_29.geometry}
        material={materials.emis}
        position={[3.78, -0.715, -8.385]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_31.geometry}
        material={materials.material}
        position={[-46.163, -1.682, 6.744]}
        rotation={[-2.905, 1.271, 2.897]}
        scale={[2.496, 2.642, 2.332]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_33.geometry}
        material={materials.material}
        position={[3.78, 9.376, -53.733]}
        scale={2.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_35.geometry}
        material={materials.material}
        position={[6.344, 8.63, -53.609]}
        rotation={[0, -0.032, 0]}
        scale={2.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_37.geometry}
        material={materials.material}
        position={[45.504, -1.275, 7.24]}
        rotation={[0, -1.525, 0]}
        scale={2.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_39.geometry}
        material={materials.material}
        position={[2.847, -1.275, 37.888]}
        rotation={[-0.072, 0.256, 0]}
        scale={[1.923, 2.64, 1.891]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_41.geometry}
        material={materials.material}
        position={[35.563, -1.682, 33.915]}
        rotation={[-3.049, -0.717, -3.099]}
        scale={[2.496, 2.642, 2.332]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_43.geometry}
        material={materials.material}
        position={[-24.174, -1.275, 25.635]}
        rotation={[-Math.PI, 0.277, -Math.PI]}
        scale={2.64}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_91.geometry}
        material={materials.material_8}
        position={[3.78, 9.376, -53.733]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.192}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Object_93.geometry}
        material={materials.terain_main}
        position={[3.78, -0.747, -8.385]}
        rotation={[0, 1.228, 0]}
        scale={52.195}
      />
    </group>
  )
}

useGLTF.preload('/Environment.glb')