import './HighlightedProjectTitleElement.css';

function HighlightedProjectTitleElement({ title, subtitle, hyperLink, icon, contributors = null }) {

    return (
        <div className='highlighted-project'>
            <div className='highlighted-project-info'>
                {/* If hyperlink is not null it will create an 'a'-tag and an 'i'-tag inside the 'h1'*/}
                <h1>{ hyperLink ? (<a href={hyperLink} target='_blank' rel='noreferrer'>{title}<i className={icon}></i></a>) : title }</h1>
                <p>{subtitle}</p>
                
                {/* Checks if the list contains Items, if so show them and check if they have a hyperlink */}
                {contributors?.length > 0 ? (
                    <>
                        <h2>Contributors</h2>
                        <ul>
                            {contributors.map((contributor, index) => (
                                <li key={index}>{ contributor.link ? (
                                    <a href={ contributor.link } target='_blank' rel='noreferrer'>{ contributor.name }</a>
                                ) : (contributor.name) }</li>
                            ))}
                        </ul>
                    </>
                ) : null }
            </div>
        </div>
    );
  }
  
  export default HighlightedProjectTitleElement;
  