import utils from '../Utils/Utils';
import './ProjectScrollElement.css';

import React, { useContext } from 'react';
import { StateContext } from './ContextWrapper.js';

function ProjectScrollElement( { title, projectIndex } ) {
    const { setHighlightedProject } = useContext(StateContext);

    const handleClick = () => {
        const projectInfo = utils.GetProjectInfo(projectIndex);
        setHighlightedProject(projectInfo);
      };
      
    return (
        <a href='#project-top'>
            <div className='project-scroll-element clickEvent' onClick={handleClick}>
                <h2>{title}</h2>
            </div>              
        </a>
    );
  }
  
  export default ProjectScrollElement;