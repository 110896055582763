import './Footer.css';

function Footer() {
  return (
    <footer>
        <ul>
            <li><a href="https://www.linkedin.com/in/gauthier-gesquiere/" target="_blank" rel='noreferrer'><i class="fa-brands fa-linkedin"></i></a></li>
            <li><a href="https://itch.io/profile/gauthier-gesquiere" target="_blank" rel='noreferrer'><i class="fa-brands fa-itch-io"></i></a></li>
            <li><a href="https://github.com/GauthierGesquiere" target="_blank" rel='noreferrer'><i class="fa-brands fa-github"></i></a></li>
        </ul>
    </footer>
  );
}

 export default Footer;