import PageTitle from '../Components/PageTitle';
import AboutMeList from '../Components/AboutMeList';
import PopularProjectList from '../Components/PopularProjectList';
import HighlightedProjectMain from '../Components/HighlightedProjectMain';

const title = "Gauthier Gesquiere";
const subtitle = ["AI Programmer graduated Digital Art & Entertainment"]

function MainApp() {
  document.body.style.overflowY = "visible";
  return (
    <>      
        <PageTitle title={title} subtitle={subtitle} isBoldDescription={true} />
        <AboutMeList />
        <PopularProjectList />
        <HighlightedProjectMain/>
    </>
  );
}

export default MainApp;
