import ProjectsData from '../assets/data/ProjectInfo.json';
import GalleryData from '../assets/data/GalleryInfo.json'
import EducationsData from '../assets/data/EducationTable.json'
import SkillsData from '../assets/data/SkillsTable.json'
import ExperienceData from '../assets/data/Experience.json'

export function GetProjectList() {
    let ProjectList = [];

    ProjectsData.projects.forEach((project, index) => {
        ProjectList.push(project.info.title.text);
    });

    return ProjectList;
} 

export function GetProjectInfo(index) {
    return ProjectsData.projects[index];
}

export function GetData() {
    return ProjectsData.projects;
}

export function GetGalleryInfo() {
    return GalleryData;
}

export function GetEducationData() {
    return EducationsData;
}

export function GetExperienceData() {
    return ExperienceData;
}

export function GetSkillsData() {
    return SkillsData;
}

//https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffle(array) {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }

const utils = {
    GetProjectList,
    GetProjectInfo,
    GetData,
    GetGalleryInfo,
    GetEducationData,
    GetSkillsData,
    GetExperienceData,
    shuffle
};

export default utils;