import './GalleryApp.css';

import GalleryItem from '../Components/GalleryItem';

import utils, { GetGalleryInfo } from '../Utils/Utils';
import PageTitle from '../Components/PageTitle';

function GalleryApp() {
    document.body.style.overflowY = "visible";

    const GalleryData = GetGalleryInfo();
    utils.shuffle(GalleryData);
 
    return (
        <>      

            <PageTitle title={'Gallery'} subtitle={['Below are some of the interesting images showcasing the various projects featured in my portfolio. Feel free to explore and see the range of work I have done.']}/>
            <div class="gallery">
                {GalleryData.map((item, index) => (
                    <GalleryItem title={item.title} source={item.src} projectIndex={item.project} key={index} />
                ))}
            </div>
        </>
    );
}

export default GalleryApp;
