import './PopularProjectElement.css';

import React, { useContext } from 'react';
import { StateContext } from './ContextWrapper.js';

import utils from '../Utils/Utils';

function PopularProjectElement({ imgSource, title, subtitle, projectIndex }) {
  const { setHighlightedProject } = useContext(StateContext);

    // TODO: link to certain project
    const handleClick = () => {
      const projectInfo = utils.GetProjectInfo(projectIndex);
      setHighlightedProject(projectInfo);
    };

    return (
      <div className='popular-project-element clickEvent' onClick={handleClick}>
            <a href='#project-top'>
                <img src={imgSource} alt=''/>
                <div className='element-text'>
                    <h2>{title}</h2>
                    <p>{subtitle}</p>
                </div>
            </a>
      </div>
    );
  }
  
  export default PopularProjectElement;
  